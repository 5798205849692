// Modal
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";

// User Register
export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

// Current User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';

//
export const SET_WEB3 = 'SET_WEB3';
export const WALLET_CONNECT = 'WALLET_CONNECT';
export const SET_USER_JWT = 'SET_USER_JWT';