import React from "react";
import ReactDOM from "react-dom";
import './assets/css/owl.carousel.css';
import './assets/css/owl.theme.css';
import './assets/css/owl.transitions.css';
import './assets/css/style.css';
import './assets/css/de-grey.css';
import './assets/css/colors/scheme-04.css';
import './assets/css/coloring.css';

import App from './App';


ReactDOM.render(<App />, document.getElementById("root"));
