// import constant
import {
    SET_USER_JWT,
} from '../constant';

const initialState = ''

const userJwtAuth = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_JWT:
            return action.data
        default:
            return state;
    }

}

export default userJwtAuth;