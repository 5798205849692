import { combineReducers } from "redux";
import authReducer from "./authReducer";

//
import web3Reucer from './webReducer';
import walletConnection from './walletConnection';
import userJwtAuth from './userjwttoken'

export default combineReducers({
    auth: authReducer,
    web3Reucer,
    walletConnection,
    userJwtAuth
});